table.subjectsTable
{
   border: 2px solid black;
   border-spacing: 0;
   margin-bottom: 1rem;
}

table.subjectsTable tr
{
   background-color: #fff;
}

table.subjectsTable td
{
    padding: 0.4rem 1rem;
    hyphens: auto;
    font-size: 1.2rem;
    border-top: 1px solid lightgray;
}

table.subjectsTable th
{
    padding: 1.2rem 1rem 0.4rem;
    hyphens: auto;
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 400;
    text-align: left;
}

table.subjectsTable tr.level_1
{
   background-color: bisque;
}

table.subjectsTable tr.level_1:hover,
table.subjectsTable tr.level_2:hover
{
   background-color: #fff;
   cursor: pointer;
}

table.subjectsTable tr.level_1:active,
table.subjectsTable tr.level_2:active
{
   background-color: #eee;
}

table.subjectsTable tr.level_2
{
   background-color: lightblue;
}


.t-Button
{
 /*display: none;*/
}

div.quota
{
   padding: 0.4rem;
   -webkit-hyphens: auto;
   -ms-hyphens: auto;
    hyphens: auto;
   font-size: 1.2rem;
   border: 1px solid black;
}

.quotaDadesBancaries
{
   margin: 2rem 0;
}

.quotaTitol,
.quotaNomCamp
{
   line-height: 1.6rem;
    font-weight: bolder;
}

.dropZone
{
   display: inline-block;
   width: 100%;
   border: 1px solid #dfdfdf;
   border-radius: 2px;
   background-color: #f9f9f9;
   color: #393939;
   font-size: 1.2rem;
   padding: 0.3rem;
   line-height: 1.6rem;
   vertical-align: top;
   transition: background-color 0.1s ease, border 0.1s ease;
}

.quotaTitol
{
   font-size: 1.8rem;
   padding-top: 1rem;
}

.quotaDadesBancaries
{
   font-size: 1.4rem;
   line-height: 2rem;
}

table.quota
{
   padding: 0.4rem;
   -webkit-hyphens: auto;
   -ms-hyphens: auto;
    hyphens: auto;
   font-size: 1.2rem;
   min-width: 50%;
}

table.quota th
{
   text-align: left;
   font-size: 1.2rem;
   padding: 0.4rem;
   margin: 0.6rem 0;
}

table.quota td
{
   padding: 0.4rem 1rem;
   border-top: 1px solid  lightgray
}

table.quota tr td:nth-child(2)
{
   text-align: right;
}

table.quota td.quotaTotal
{
   font-size: 1.4rem;
   font-weight: 800;
}

p {
   font-family: 'OpenSansRegular', Helvetica, Arial, sans-serif;
   font-size: 1.2rem;
   padding: 0.4rem 0;
   -webkit-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto;
   display: inline-block;
   line-height: 1.5rem;
   text-align: justify;
}

ol > p
{
   line-height: 1.5rem;
}

ul > li,
ol > li {
   font-family: 'OpenSansRegular', Helvetica, Arial, sans-serif;
   font-size: 1.2rem;
   padding: 0.4rem 0;
   line-height: 1.5rem;
}

button.button,
button.submitButton
{
   font-size: 1.7rem;
   color: white;
   font-weight: bold;
   border: 0px solid white;
   background-color: #c00000;
   padding: 0.4rem 1rem;
   margin: 4rem 1rem;
   border-radius: 0.5rem;
   cursor: pointer;
}

div.submitButtonDiv
{
   margin: 4rem 1rem;
   font-size: 1.5rem;
}

button.submitButton
{
   font-size: 1.9rem;
   margin: 1rem 1rem;
   padding: 0.5rem 1rem;
}

button.button:active,
button.submitButton:active
{
   position: relative;
   top: 1px;
}

.inscripcioBlock
{
   height: 60vh;
   display: flex;
   justify-items: center;
   justify-content: center;
   align-items: center;
}

.inscripcioContingut
{
   text-align: center;
   padding: 2rem 3rem;
   border: 2px solid #333;
   box-shadow: 5px 5px 1px lightgrey;
}

.inscripcioContingut li
{
    list-style: none;
}

.inscripcioContingut h2
{
   font-size: 3rem;
   margin-bottom: 4rem;
   border-bottom: 2px solid #c00000;
}


.inscripcioContingut h3
{
   font-size: 2rem;
   margin-bottom: 4rem;
}

.inscripcioContingut h3 a
{
 text-decoration: underline;
}

.inscripcioText
{
    padding: 1rem;
}

.inscripcioNumero
{
   line-height: 6rem;
   font-size: 2rem;
   text-align: center;
   font-weight: bold;
}

small.datesMatricula
{
    color: #333;
    font-size: 1rem;
}

h4, h5
{
    font-weight: 600;
}

.authorizations h4
{
   border-bottom: 1px solid black;
}

#suportAjMartorell {
   width: 100%;
   display: flex;
   text-align: middle;
   flex-direction: row;
   flex-wrap: nowrap;
   align-content: center;
   align-items: center;
   margin-top: 20px;
}

#suportAjMartorell img {
   width: 150px;
   margin-right: 10px;
}
    

.quadrePortatilAutoritzacions
{
   max-width: 75%;
   border: 1px solid rgb(192, 0, 0);
   margin: 5px 0px 20px;
   padding: 10px;
   display: inline-flex;
}
